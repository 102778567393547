import styled from "styled-components";

export const HowITWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3b3b3b;
  height: 100%;
  padding: 3rem 0 3rem 0;
`;

export const HowITWorksH1 = styled.h1`
  color: #f7f8fa;
  font-size: 2.5rem;
`;

export const HowITWorksWrapper = styled.div`
  max-width: 1400px;
  margin: 0 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  padding-top: 30px;
  text-align: center;

  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const StepContainer = styled.div`
  text-align: center;
`;

export const StepH2 = styled.h2`
  color: #f7f8fa;
`;

export const ServiceIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  font-size: 9rem;
  padding-top: 1rem;
  color: #f7f8fa;
`;
