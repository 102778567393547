import styled from "styled-components";

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(240, 240, 240);
  height: 100%;
  padding: 1rem 0 3rem 0;
`;

export const ValuesH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;
`;

export const ValuesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  padding-top: 30px;
  text-align: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Value = styled.h2`
  font-size: 2rem;
  color: #010606;
`;

export const AboutUsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

export const AboutUsCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  min-height: 100px;
  max-width: 360px;
  padding: 15px;
  margin-top: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    min-height: 100px;

    margin-top: 30px;
  }
`;

export const AboutUsH1 = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const AboutUsH2 = styled.h2`
  margin-bottom: 10px;
`;

export const WhyP = styled.p`
  font-size: 1.2rem;
  line-height: 26px;
  max-width: 900px;
  text-align: center;
  padding: 10px 40px 50px 40px;
`;

export const AboutUsP = styled.p`
  font-size: 1.2rem;
  text-align: center;
`;
