import styled from "styled-components";

export const InputFieldLabel = styled.label`
  display: grid;
  grid-row-gap: 10px;
  color: #fff;
  font-size: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
`;

export const InputFieldTextArea = styled.textarea`
  background-color: #fff;
  border: none;
  font-size: 1rem;
  padding: 12px 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  font-weight: bold;
  color: #3b3b3b;
`;

export const InputFieldInput = styled.input`
  background-color: #fff;
  border: none;
  font-size: 1rem;
  padding: 8px 14px;
  margin: 0 auto;
  width: 100%;
`;

export const InputFieldButton = styled.input`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#ffcb66" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "8px 24px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#FFF" : "#ffcb66")};
    color: #000;
  }

  :disabled {
    background-color: grey;
    cursor: default;
  }
`;
