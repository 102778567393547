export const homeObjOne = {
  id: "cloudit",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Modern Office",
  headline: "Who's managing IT?",
  description:
    "There are many benefits to properly managed modern office technology. It provides flexibility for you to work the way you want, minimizes downtime increasing productivity, and protects your business from cyber threats. Ask yourself, how is your technology being managed? If it’s not in the hands of an expert and someone you have confidence in to do the job it might be time for a change. Reach out for a free assessment to discover what is possible.",
  buttonLabel: "Take the Assessment",
  imgStart: false,
  img: require("../../images/BodyImg-1.svg").default,
  alt: "Car",
  primary: false,
  darkText: true,
  dark: false,
  btnLink:
    "https://forms.office.com/Pages/ResponsePage.aspx?id=G3e_v866EEKXlE9bGV1X7NT9_QW2X9NAkiwvXKOnHLtUM0c4TEFQQUdBRlE2MlNSVlM3NzQ3UkpKNyQlQCN0PWcu",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Expert Advisor",
  headline: "Strategy, Planning, and Enablement",
  description:
    "With our solutions you’ll get an expert advisor to guide your IT strategy and provide a clear path for the adoption of cloud technology. Regular discussions and meetings for enablement sessions will provide accountability, identify opportunities for training, and improve system configurations.",
  buttonLabel: "Take the Assessment",
  imgStart: true,
  img: require("../../images/BodyImg-2.svg").default,
  alt: "Car",
  primary: true,
  darkText: false,
  dark: true,
  btnLink:
    "https://forms.office.com/Pages/ResponsePage.aspx?id=G3e_v866EEKXlE9bGV1X7NT9_QW2X9NAkiwvXKOnHLtUM1VKNTFLT0RNRVg3UDdDWkFROUVSRUtRRyQlQCN0PWcu",
};
