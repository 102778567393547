import React from "react";
import Headshot from "../../images/SPC_CIRHS-SM.png";
import {
  AboutOwnerContainer,
  OwnerName,
  OwnerTitle,
  AboutOwnerWrapper,
  AboutOwnerRow,
  AboutOwnerImage,
  AboutOwnerText,
  Column1,
  Column2,
  Strong,
} from "./AboutOwnerElements";

const AboutOwner = () => {
  return (
    <AboutOwnerContainer id="about">
      <AboutOwnerWrapper>
        <AboutOwnerRow>
          <Column1>
            <AboutOwnerImage src={Headshot} />
          </Column1>
          <Column2>
            <OwnerName>Sean P. Cook</OwnerName>
            <OwnerTitle>Owner, Shift IT</OwnerTitle>
            <AboutOwnerText>
              Sean is the Owner of Shift IT, a Detroit based Microsoft Partner
              and Cloud Solutions Provider. He is dedicated to helping small
              businesses transition from legacy on-premises technology to modern
              cloud-based services. A major component of his practice is guiding
              the deployment of a zero-trust security architecture that is
              necessary to ensure the protection of your business and digital
              assets from ever evolving cyber threats. Previously having
              experience in corporate IT, Sean has decided to take his advanced
              knowledge of technology systems and apply it to serving a market
              in need of his expertise. When not behind the keyboard you can
              find him supporting his community as a volunteer on Belle Isle, a
              leader in local biking, and steward of all things Detroit.
            </AboutOwnerText>
          </Column2>
        </AboutOwnerRow>
      </AboutOwnerWrapper>
    </AboutOwnerContainer>
  );
};

export default AboutOwner;
