import styled from "styled-components";

export const MCFormContainer = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 0 1.5rem 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const MCFormWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  justify-content: center;
`;

export const MCFormH2 = styled.h2`
  color: #fff;
  padding-bottom: 0.7rem;
`;

export const Form = styled.form`
  width: 100%;

  @media screen and (max-width: 768px) {
    max-width: 194px;
  }
`;

export const InputFieldContainer = styled.div`
  width: 100%;
`;

export const InputFieldWrapper = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  padding: 0 0 20px 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const MSG = styled.div`
  color: #fff;

  a:link {
    cursor: pointer;
    color: #fff;
  }

  a:visited {
    color: #ffcb66;
  }
`;

export const SubmitWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 300px;
  }
`;
