import React from "react";
import {
  DropMenuContainer,
  Icon,
  CloseIcon,
  DropMenuWrapper,
  DropMenuList,
  DropMenuLink,
  BtnWrap,
  NavButton,
} from "./DropMenuElements";

const DropMenu = ({ isOpen, toggle }) => {
  return (
    <DropMenuContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropMenuWrapper>
        {/* <DropMenuList>
          <DropMenuLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            About
          </DropMenuLink>
          <DropMenuLink
            to="solutions"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Solutions
          </DropMenuLink>
          <DropMenuLink
            to="cloudit"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Modern Office
          </DropMenuLink>
          <DropMenuLink
            to="solutions"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Solutions
          </DropMenuLink>
          <BtnWrap>
            <NavButton
              href="https://portal.shift-it.io"
              primary={true}
              dark={true}
              target="_blank"
            >
              Client Portal
            </NavButton>
          </BtnWrap>
        </DropMenuList> */}
      </DropMenuWrapper>
    </DropMenuContainer>
  );
};

export default DropMenu;
