import styled from "styled-components";

export const AboutOwnerContainer = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.5rem 0 1.5rem 0;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "rgb(240, 240, 240)")};
`;

export const AboutOwnerWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
`;

export const OwnerName = styled.h2`
  font-size: 2rem;
`;

export const OwnerTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffcb66;
  padding-bottom: 10px;
`;

export const AboutOwnerRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const AboutOwnerImage = styled.img`
  width: 100%;
  max-width: 450px;
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    max-width: 350px;
  }
`;

export const Column1 = styled.div`
  padding: 15px 15px 0 15px;
  grid-area: col1;
  padding: 30px;

  @media screen and (max-width: 768px) {
    margin: auto;
    padding-top: 0;
  }
`;

export const Column2 = styled.div`
  padding: 0 15px;
  grid-area: col2;
`;

export const AboutOwnerText = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 30px;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Strong = styled.span`
  font-weight: 550;
  font-size: 1.15rem;
`;
