import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaLinkedin } from "react-icons/fa";
import MCForm from "../MCForm";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Logo,
} from "./FooterElements";
import ShiftITLogo from "../../images/ShiftITLogo.png";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        {/* <MCForm />
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Microsoft</FooterLinkTitle>

              <FooterLink
                href="//myapplications.microsoft.com"
                target="_blank"
                aria-label="My Apps"
              >
                My Apps Portal
              </FooterLink>
              <FooterLink
                href="//myaccount.microsoft.com"
                target="_blank"
                aria-label="My Account"
              >
                My Account Portal
              </FooterLink>
              <FooterLink
                href="https://teams.microsoft.com/"
                target="_blank"
                aria-label="Teams Online"
              >
                Teams Online
              </FooterLink>
              <FooterLink
                href="//outlook.office.com"
                target="_blank"
                aria-label="Outlook Online"
              >
                Outlook Online
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Resources</FooterLinkTitle>
              <FooterLink
                href="https://adoption.microsoft.com/become-a-champion/#join"
                target="_blank"
                aria-label="Champion Program"
              >
                Champion Program
              </FooterLink>
              <FooterLink
                href="https://techcommunity.microsoft.com/"
                target="_blank"
                aria-label="Tech Community"
              >
                Tech Community
              </FooterLink>
              <FooterLink
                href="https://answers.microsoft.com/en-us/"
                target="_blank"
                aria-label="MS Community"
              >
                MS Community
              </FooterLink>
              <FooterLink
                href="https://docs.microsoft.com/en-us/"
                target="_blank"
                aria-label="MS Docs"
              >
                MS Docs
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Knowledge</FooterLinkTitle>
              <FooterLink
                href="https://docs.microsoft.com/en-us/learn/"
                target="_blank"
                aria-label="MS Learn"
              >
                MS Learn
              </FooterLink>
              <FooterLink
                href="https://support.microsoft.com/en-us/office/office-quick-starts-25f909da-3e76-443d-94f4-6cdf7dedc51e?ui=en-us&rs=en-us&ad=us"
                target="_blank"
                aria-label="Office Quick Starts"
              >
                Office Quick Starts
              </FooterLink>
              <FooterLink
                href="https://support.microsoft.com/en-us/modernworkplace?redirectSourcePath=%252fen-us%252farticle%252fOffice-365-productivity-training-af07cb6b-980d-4f33-8599-322582767408"
                target="_blank"
                aria-label="Modern Workplace Training"
              >
                Modern Workplace
              </FooterLink>
              <FooterLink
                href="https://opportunity.linkedin.com/skills-for-in-demand-jobs"
                target="_blank"
                aria-label="Skills Training"
              >
                Skills Training
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>White Papers</FooterLinkTitle>

              <FooterLink
                href="https://shiftitio.sharepoint.com/:b:/s/ShiftIT/EYRAgFwAjyRNjaQcCdgD36YBMeJDeWrJX7gC_WHtKdf9Rw"
                target="_blank"
                aria-label="B2B Collaboration"
              >
                B2B Collaboration
              </FooterLink>
              <FooterLink
                href="https://shiftitio.sharepoint.com/:b:/s/ShiftIT/EVkubtZQWcJFrFbZktfwPnsBb3Ws1D51weGhi5WaBRwDnw"
                target="_blank"
                aria-label="Modern Device Management"
              >
                Modern Device Management
              </FooterLink>
              <FooterLink
                href="//www.microsoft.com/en-us/research/wp-content/uploads/2016/06/Microsoft_Password_Guidance-1.pdf"
                target="_blank"
                aria-label="Password Guidance"
              >
                Password Guidance
              </FooterLink>
              <FooterLink
                href="https://shiftitio.sharepoint.com/:b:/s/ShiftIT/EbbwVrev1oxCjs75gP9D1CkBazCWHljO2ZlfYCW-ENnf2Q"
                target="_blank"
                aria-label="Non-profit Digital Transformation"
              >
                Non-profit Digital Transformation
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer> */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <Logo src={ShiftITLogo} alt="Shift IT Logo" />
            </SocialLogo>
            <WebsiteRights>
              Shift IT LLC © {new Date().getFullYear()} All rights reserved.
              <br /> 915 W Peachtree St NW, Unit 15105 Atlanta, GA 30309
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.linkedin.com/company/shift-it-io"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
