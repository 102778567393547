import React from "react";
import { AiOutlineCloudServer } from "react-icons/ai";
import { MdSecurity } from "react-icons/md";
import { MdDevices } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import {
  HowITWorksContainer,
  HowITWorksH1,
  HowITWorksWrapper,
  StepContainer,
  StepH2,
  ServiceIcons,
} from "./HowITWorksElements";

const HowITWorks = () => {
  return (
    <HowITWorksContainer id="solutions">
      <HowITWorksH1>Solutions</HowITWorksH1>
      <HowITWorksWrapper>
        <StepContainer>
          <StepH2>Cloud</StepH2>
          <StepH2>Transformations</StepH2>
          <ServiceIcons>
            <AiOutlineCloudServer />
          </ServiceIcons>
        </StepContainer>
        <StepContainer>
          <StepH2>Zero-Trust</StepH2>
          <StepH2>Security Deployment</StepH2>
          <ServiceIcons>
            <MdSecurity />
          </ServiceIcons>
        </StepContainer>
        <StepContainer>
          <StepH2>Modern Device</StepH2>
          <StepH2>Management</StepH2>
          <ServiceIcons>
            <MdDevices />
          </ServiceIcons>
        </StepContainer>
        <StepContainer>
          <StepH2>Technology</StepH2>
          <StepH2>Support</StepH2>
          <ServiceIcons>
            <BiSupport />
          </ServiceIcons>
        </StepContainer>
      </HowITWorksWrapper>
    </HowITWorksContainer>
  );
};

export default HowITWorks;
