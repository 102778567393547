import React, { useState } from "react";
import BodySection from "../components/BodySection";
import { homeObjOne } from "../components/BodySection/Data";
import { homeObjTwo } from "../components/BodySection/Data";
import DropMenu from "../components/DropMenu";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
// import Services from "../components/Services";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import HowITWorks from "../components/HowITWorks";

import AboutOwner from "../components/AboutOwner";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <DropMenu isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutOwner />
      {/*  <AboutUs />
      <HowITWorks />
      <BodySection {...homeObjOne} />
      <BodySection {...homeObjTwo} />
      <Services /> */}
      <Footer />
    </>
  );
};

export default Home;
